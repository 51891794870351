import { useCallback, useEffect, useState } from 'react';
import { atcb_action } from 'add-to-calendar-button';
import { getDeviceType } from './deviceType';
import { addToDb, updateDb } from './firebase';
import videomp from './assets/video.mp4';
import './App.scss';
import './App2.scss';

function App() {
  const [device, setDevice] = useState('');
  const [already, setAlready] = useState(localStorage.getItem('already') || '');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const dev = getDeviceType();
    setDevice(dev);
  }, []);

  const handleRetry = useCallback(() => {
    setAlready(null);
    setDisabled(false);
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    const userWithDoc = localStorage.getItem('already');
    const addtoCalendar = event.target[4].checked;
    const payload = {
      family: event.target[0].value,
      partnerOne: event.target[1].value,
      partnerTwo: event.target[2].value,
      message: event.target[3].value,
      addedToCalendar: addtoCalendar
    };

    if (userWithDoc) {
      updateDb(userWithDoc, payload).then(() => {
        setError(false);
        setSuccess(true);
        setDisabled(true);
      }).catch(err => {
        console.log(err);
        setSuccess(false);
        setError(true);
      });
    } else {
      addToDb(payload).then(result => {
        localStorage.setItem('already', result);
        setSuccess(true);
        setDisabled(true);
      }).catch(err => {
        console.log(err);
        setError(true);
      });
    }


    if (addtoCalendar) {
      atcb_action({
        name: 'Adriana si Catalin',
        startDate: '2025-08-09',
        location: 'Liria Events',
        description: 'Petrecere.',
        options: device,
        timeZone: 'Europe/Bucharest',
        iCalFileName: 'Reminder-Event',
      });
    }

  }, [device]);

  return (
    <div className="video">
      <video autoPlay muted loop id="background-video">
        <source src={videomp} type="video/mp4" />
      </video>

      <div className='video__data'>
        <form onSubmit={handleSubmit}>
          {
            already && <p>Ai trimis deja raspuns. Apasa <span onClick={handleRetry}>aici</span> daca vrei sa actualizezi.</p>
          }
          {
            success && <p>Raspunsul a fost trimis cu succes. Multumim.</p>
          }
          {
            error && <p>Eroare la trimiterea raspunsului. Te rugam sa incerci mai tarziu.</p>
          }
          <input type="text" placeholder='Nume de familie' disabled={already || disabled}/>
          <input type='text' placeholder='Prenume partener 1' disabled={already || disabled}/>
          <input type='text' placeholder='Prenume partener 2' disabled={already || disabled}/>
          <textarea placeholder='Lasa-ne un mesaj aici.' disabled={already || disabled}/>
          <div className='checkbox'>
            <input type='checkbox' disabled={already || disabled}/><span>Adauga evenimentul in calendar.</span>
          </div>
          <input type='submit' value='Trimite' disabled={already || disabled}/>
        </form>
      </div>
    </div>
  );

  // return (
  //   <div className="app">
  //     <div className='app__container'>
  //       <form onSubmit={handleSubmit}>
  //         {
  //           already && <p>Ai trimis deja raspuns. Apasa <span onClick={handleRetry}>aici</span> daca vrei sa actualizezi.</p>
  //         }
  //         {
  //           success && <p>Raspunsul a fost trimis cu succes. Multumim.</p>
  //         }
  //         {
  //           error && <p>Eroare la trimiterea raspunsului. Te rugam sa incerci mai tarziu.</p>
  //         }
  //         <input type="text" placeholder='Nume de familie' disabled={already || disabled}/>
  //         <input type='text' placeholder='Prenume partener 1' disabled={already || disabled}/>
  //         <input type='text' placeholder='Prenume partener 2' disabled={already || disabled}/>
  //         <textarea placeholder='Lasa-ne un mesaj aici.' disabled={already || disabled}/>
  //         <div className='checkbox'>
  //           <input type='checkbox' disabled={already || disabled}/><span>Adauga evenimentul in calendar.</span>
  //         </div>
  //         <input type='submit' value='Trimite' disabled={already || disabled}/>
  //       </form>
  //     </div>
  //   </div>
  // );
}

export default App;
