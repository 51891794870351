// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB7Ip7C5nWdAP5HC_oK6bCgc5-3eDzr3cE",
  authDomain: "hcm-platform.firebaseapp.com",
  databaseURL: "https://hcm-platform.firebaseio.com",
  projectId: "hcm-platform",
  storageBucket: "hcm-platform.appspot.com",
  messagingSenderId: "58230379642",
  appId: "1:58230379642:web:a76ca66650bbe6c7f817c3",
  measurementId: "G-YNFQFLSWJR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addToDb = async (payload) => {
    const docRef = await addDoc(collection(db, 'wedding'), payload);
    return docRef.id;
};

export const updateDb = async (id, payload) => {
  const docRef = doc(db, 'wedding', id);
  await updateDoc(docRef, payload);
  return docRef;
};