export const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Check if the user is on an Android device
    if (/android/i.test(userAgent)) {
      return ["Google"];
    }
  
    // Check if the user is on an iOS device
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return ["Apple", "Google"];
    }
  
    // Check if the user is on a Windows device (e.g., laptop)
    if (/windows/i.test(userAgent)) {
      return ['Apple', 'Google', 'Outlook.com'];
    }
  
    // Default value if the device is not identified
    return "Unknown";
  };